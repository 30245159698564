@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  margin-top: vars.$nodeSpacing;
  margin-bottom: vars.$gutter;
}

.links {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: dense;
  column-gap: 32px;
  row-gap: 4px;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: vars.$medium) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: vars.$extra-large) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.link {
  background: white;
  display: grid;
  align-items: center;
  padding: 0 vars.$gutter;
  font-size: 16px;
  text-decoration: none;
  color: colors.$dark-text;
  border-radius: 4px;
  grid-template-columns: max-content min-content;
  justify-content: space-between;
  padding: 16px;
  column-gap: vars.$nodeSpacing;

  @media screen and (min-width: vars.$medium) {
    padding: 24px 30px;
    font-size: 21px;
  }

  &:hover {
    color: colors.$primary-color;
  }
}

.singleLink {
  width: fit-content;
}

.allLink {
  border: 2px solid colors.$primary-color;
  color: colors.$primary-color;
  box-sizing: border-box;
}
